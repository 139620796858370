@import "~@webpackCSS/mixins/colors";

$print-border: #eeeeee;
$left-column-width: 39%;
$right-column-width: 58%;

.intro-container {
  float: right;
  margin: 0;
  padding: 0;
  width: $right-column-width;
}

.ingredients-container {
  clear: left;
  display: block;
  float: left;
  overflow: visible;
  position: static;
  width: $left-column-width;
  padding: 0;
  margin: 10px 0 30px;

  .ingredient-list {
    font-size: 12px;
    border: 0;
    box-shadow: none;
    padding: 0;
  }
}

.steps-container {
  clear: right;
  float: right;
  width: $right-column-width;
  margin: 15px 0 0;
  padding: 0;
}

.step {
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid $print-border;
  border-style: dashed;
  border-radius: 0;
  margin: 0;
  padding: 10px 0;

  &::before {
    background: transparent;
    color: $tw-cookpad-gray-700;
    font-weight: 600;
    font-size: 16px;
    width: auto;
    line-height: 1.1em;
  }
}

.clamp-none-on-print {
  -webkit-line-clamp: unset;

  p:not(:first-child) {
    display: block;
  }
}
